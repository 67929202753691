// extracted by mini-css-extract-plugin
export var textp = "integrationslider-module--textp--261MV";
export var intocap = "integrationslider-module--intocap--kjTpt";
export var outtercap = "integrationslider-module--outtercap--2dOIP";
export var slidingbackground = "integrationslider-module--slidingbackground--OQgs7";
export var slide = "integrationslider-module--slide--2Qera";
export var wrapper = "integrationslider-module--wrapper--2FVnv";
export var carouselItem = "integrationslider-module--carouselItem--1llpv";
export var carouselHeader = "integrationslider-module--carouselHeader--2xb6B";
export var carouselHeaderEntry = "integrationslider-module--carouselHeaderEntry--1oM3q";
export var button = "integrationslider-module--button--a5W0O";
export var container = "integrationslider-module--container--3QdpG";
export var title = "integrationslider-module--title--oOkrA";
export var outer = "integrationslider-module--outer--2KRny";
export var intlink = "integrationslider-module--intlink--3k4ds";