// extracted by mini-css-extract-plugin
export var container = "venue-module--container--NKuGt";
export var outer = "venue-module--outer--2afHu";
export var venuelabel = "venue-module--venuelabel--183DU";
export var faretail = "venue-module--faretail--1KtQC";
export var facafe = "venue-module--facafe--3nvQ7";
export var fabar = "venue-module--fabar--19crU";
export var farestaurant = "venue-module--farestaurant--DvvKx";
export var fafranchise = "venue-module--fafranchise--1uBuC";
export var fahotel = "venue-module--fahotel--1HHer";
export var famobile = "venue-module--famobile--1WRou";