// extracted by mini-css-extract-plugin
export var tvdownload = "index-module--tvdownload--t9cvB";
export var supportlogo = "index-module--supportlogo--3V1R0";
export var ticktext = "index-module--ticktext--1juND";
export var whatwedologo = "index-module--whatwedologo--3h_TH";
export var whatwedopic = "index-module--whatwedopic--3ImIG";
export var tickContainer = "index-module--tickContainer--2oan6";
export var supportcontent = "index-module--supportcontent--1A4q2";
export var psupport = "index-module--psupport--2a8Ts";
export var supporthead = "index-module--supporthead--2-ZKs";
export var install = "index-module--install--kvjb5";
export var train = "index-module--train--1yJY7";
export var repair = "index-module--repair--my-YT";
export var parallax = "index-module--parallax--1nf1x";
export var featureSection = "index-module--featureSection--22lD7";
export var productSummary = "index-module--productSummary--6XF8x";
export var tick = "index-module--tick--3ooK3";
export var venutitle = "index-module--venutitle--1oOu5";
export var venutext = "index-module--venutext--Rg2vO";